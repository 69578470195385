//
// reboot.scss
//

body {
  padding-left: 0 !important;
  padding-right: 0 !important;

  &.loading {
    visibility: hidden;
    opacity: 0;
  }
}


// Forms
label {
  font-weight: $label-font-weight;
}


button:focus {
  outline: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.tab-content {
  margin-bottom: 20px;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding-top: 20px;
}

.ImgBtn {
  padding: 8px 20px;
  color: black;
  border: none;
  background-color: rgba(247, 184, 75, 0.25);
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.addMarginBtm {
  margin-bottom: 20px;
}

.popupHeading {
  text-align: center;
  color: steelblue;
}

.button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button2 {
  background-color: #008CBA;
}

.addpadding {
  padding-top: 25px;
}

.error-message{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #f1556c;
}