.toastContainer {
    width: auto;
}

.toastBody {
    min-height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 16px;
}

.toastBody button {
    align-self: auto;
    padding-left: 16px;
    opacity: 0.25;
}

.success {
    background: var(--lightgreen);
}

.error {
    background: #F2E7E6;
}

.toastText {
    font-family: var(--medium500);
    font-size: 14px;
    color: #000000;
}

.displayContent {
    display: flex;
    align-items: center;
}

.displayContent .label {
    padding-left: 16px;
}
